import {
  Component,
  Inject,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, take } from 'rxjs';
import { AppMetadataQuery } from '../../../../application/ports/primary/app-metadata.query';
import {
  CreatesLMSimulationQueryPort,
  CREATES_LM_SIMULATION_QUERY,
} from '../../../../application/ports/primary/creates-lm-simulation.query-port';
import {
  GetsLabelManagerMetaQueryPort,
  GETS_LABEL_MANAGER_META_QUERY_PORT,
} from '../../../../application/ports/primary/gets-label-manager-meta.query-port';
import {
  GetsLabelManagerReportLengthQueryPort,
  GETS_LABEL_MANAGER_REPORT_LENGTH_QUERY_PORT,
} from '../../../../application/ports/primary/gets-label-manager-report-length.query-port';
import { LabelManagerReportDateData } from '../../../../application/ports/primary/label-manager-report.query';
import {
  ShowsSimulationOptionQueryPort,
  SHOWS_SIMULATION_OPTION_QUERY_PORT,
} from '../../../../application/ports/primary/shows-simulation-option.query-port';
import { LabelManagerResultsComponent } from '../label-manager-results/label-manager-results.component';
import {
  LOADS_LABEL_MANAGER_REPORT_QUERY_PORT,
  LoadsLabelManagerReportQueryPort,
} from '../../../../application/ports/primary/loads-label-manager-report.query-port';

@Component({
  selector: 'lib-cobiro-pro-label-manager-start-page',
  templateUrl: './label-manager-start-page.component.html',
  styleUrls: ['./label-manager-start-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelManagerStartPageComponent implements OnInit {
  @Output()
  startIntegration = new EventEmitter<void>();

  @ViewChild(LabelManagerResultsComponent, { static: false })
  resultComponent: LabelManagerResultsComponent;
  readonly emptyDataset: LabelManagerReportDateData = {
    date: new Date(),
    dataLabels: ['overIndex', 'index', 'nearIndex', 'noIndex', 'underIndex'],
    totalProducts: 1089,
    chartSeries: [{ data: [95, 146, 122, 659, 67] }, { data: [994, 943, 967, 430, 1022] }],
    segments: null,
  };

  readonly app$: Observable<AppMetadataQuery> = this._getLabelManagerMetaQueryPort.getAppMeta();
  readonly showSimulationBlock$ = this._showSimulationOptionQueryPort.showsSimulationOption({
    clientId: this._activatedRoute.snapshot.paramMap.get('shopId'),
  });

  private readonly _showBlur$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  readonly showBlur$ = combineLatest([
    this._showBlur$.asObservable(),
    this._getsLabelManagerReportLengthQueryPort
      .getReportLength()
      .pipe(map((res: number) => res === 0)),
  ]).pipe(map(([showBlur, hasReportData]: [boolean, boolean]) => showBlur && hasReportData));

  constructor(
    @Inject(LOADS_LABEL_MANAGER_REPORT_QUERY_PORT)
    private readonly _loadsLabelManagerReportQueryPort: LoadsLabelManagerReportQueryPort,
    @Inject(SHOWS_SIMULATION_OPTION_QUERY_PORT)
    private readonly _showSimulationOptionQueryPort: ShowsSimulationOptionQueryPort,
    @Inject(CREATES_LM_SIMULATION_QUERY)
    private readonly _createLMSimulationQueryPort: CreatesLMSimulationQueryPort,
    @Inject(GETS_LABEL_MANAGER_META_QUERY_PORT)
    private readonly _getLabelManagerMetaQueryPort: GetsLabelManagerMetaQueryPort,
    @Inject(GETS_LABEL_MANAGER_REPORT_LENGTH_QUERY_PORT)
    private readonly _getsLabelManagerReportLengthQueryPort: GetsLabelManagerReportLengthQueryPort,
    private readonly _activatedRoute: ActivatedRoute,
  ) {}
  ngOnInit(): void {
    this._loadsLabelManagerReportQueryPort.loadReport(false).pipe(take(1)).subscribe();
  }

  runAdditionalAppFlowBeforeStart() {
    this.startIntegration.emit();
  }

  async onGetSimulationButtonClicked(): Promise<void> {
    const status = await firstValueFrom(this._createLMSimulationQueryPort.createLMSimulation());
    if (status) {
      this._showBlur$.next(false);
      this.resultComponent.loadData();
    }
  }
}
